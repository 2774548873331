<template>
    <Catalog name="Dias Festivos Bancarios" 
            @editing="onEdit" 
            @new="newEntity" 
            v-model="entity" 
            :validate="validate" 
            :rules="rules" 
            :headers="headers" 
            :inputs="inputs" />
</template>
  
  <script>
  import Catalog from "../../../components/templates/BasicCatalog.vue";
  import { bw_bank_holiday } from '../../../models/custom/business_wallet/BWBankHoliday';
  import Session from "../../../mixins/sessionMixin";
  import { HeaderGrid, Rule, CatalogInput, ValidateForm } from '../../../utilities/General';
  
  export default {
    props: {
      voucher_type: null
    },
    data() {
      return {
        test: null,
        entity: new bw_bank_holiday(),
        inputs: [
          new CatalogInput("Descripcion", "description", "text"),
          new CatalogInput("Fecha", "bank_holiday", "calendar"),
        ],
        rules: [
          new Rule({ name: "description" }),
          new Rule({ name: "bank_holiday" }),
        ],
        headers: [
          new HeaderGrid("Descripcion", "description"),
          new HeaderGrid("Fecha", "bank_holiday", { type: "date"}),
        ],
        validate: new ValidateForm(),
      }
    },
    mixins: [Session],
    components: { Catalog },
    methods: {
      newEntity() {
        this.entity = new bw_bank_holiday(this.session); 
        this.validate.clear();
      },
      onEdit() {
        this.entity.bank_holiday = new Date(this.entity.bank_holiday);
      }
    },
    async created() {
      this.validate = new ValidateForm(this.rules);
      this.entity = new bw_bank_holiday(this.session); 
    }
  }
  </script>
  
  <style>
  
  </style>